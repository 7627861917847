.container {
  display: flex;
  justify-content: center;
  padding: 20px;
  gap: 20px;
  flex-direction: column;

  .profileContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }

  .accContainer {
    width: 100%;
    max-width: 945px;
    margin: auto;
  }
}

@media (width <= 720px) {
  .container {
    .profileContainer {
      flex-direction: column;
    }
  }
}

@media (width <= 550px) {
  .accContainer {
    button {
      text-indent: -9999px;
      width: fit-content;
      gap: 0px !important;
    }
  }
}

@media (width <= 450px) {
  .profileContainer {
    :nth-child(2) {
      height: fit-content !important;
    }
  }
}
