.formContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 7px;

  .formField {
    width: 48%;

    input {
      padding: 0.7rem;
    }

    :last-child {
      margin-top: 0;
    }
  }
}

.btnsContainer {
  display: flex;
  gap: 20px;
  padding: 0 120px;
  justify-content: center;
  margin: 25px auto 0 auto;
  width: 70%;
}

@media (width <= 500px) {
  .formContainer {
    width: 100%;
    flex-direction: column;

    .formField {
      width: 100%;
    }
  }

  .btnsContainer {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
