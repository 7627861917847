.statisticCard {
  min-width: 300px;
  width: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white-full);
  border-radius: 10px;
  padding: 20px 30px;
}

.data {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.image {
  width: 50px;
}
