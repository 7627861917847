.form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.header {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.password {
  width: 90%;
  padding-top: 20px;
}

.field {
  width: 100%;
  margin: 13px 0;
}

.btnsContainer {
  display: flex;
  gap: 20px;
  width: 50%;
  justify-content: center;
  margin: auto;
}
