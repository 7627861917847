.container {
  background-color: white;
  width: 425px;
  height: fit-content;
  box-shadow: 0px 0px 3px 0px rgb(153 153 153 / 50%);
  padding: 10px;
  border-radius: 16px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: height 0.5s;

  .cardContainer {
    align-items: center;
    position: relative;
    gap: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .imgContainer {
      display: grid;
      place-items: center;
      border-radius: 50%;
      contain: strict;
      width: 25%;
      aspect-ratio: 1/1;
      margin-right: 5px;

      img{
        object-fit: cover;
      }

      div {
        height: 100%;
        background-color: #80808080;
        width: 100%;
        border-radius: 50%;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .infoContainer {
      display: flex;
      flex-direction: column;
      width: 60%;
      align-items: flex-start;
      margin-right: 5px;
      overflow: hidden;
    }
  }

  .childrenContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 25%;
    height: 80px;
    position: relative;
    align-items: center;
    justify-content: center;

    .iconContainer {
      cursor: pointer;
    }

    .selectionContainer {
      position: absolute;
      width: 175px;
      height: fit-content;
      right: -180px;
      top: -65px;
      z-index: 5;
      border-radius: 6px;
      box-shadow: 0px 0px 3px 0px rgba(153, 153, 153, 0.5);
      padding: 8px;
      background-color: white;
    }
  }
}

@media (width <= 470px) {
  .container {
    width: 100% !important;

    .imgContainer {
      display: none !important;
    }

    .infoContainer {
      width: 100% !important;
    }

    .span {
      white-space: normal;
      overflow-wrap: anywhere;
    }
  }
}
