$bgImage1: url("../../../assets/Backgrounds/LoginBG.webp");
$bgImage2: url("../../../assets/Backgrounds/DSC_1574.webp");
$bgImage3: url("../../../assets/Backgrounds/DSC_1694.webp");
$bgImage4: url("../../../assets/Backgrounds/DSC_1757.webp");
$bgImage5: url("../../../assets/Backgrounds/DSC_1889.webp");
$bgImage6: url("../../../assets/Backgrounds/DSC_2118.webp");
.ImageHalfSize {
  width: 50vw;
  height: 100vh;
  max-width: 960px;
  max-height: 1080px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 2s;

  animation-name: slideshow;
  animation-direction: alternate-reverse;
  animation-play-state: running;
  animation-timing-function: ease-in-out;
  animation-duration: 30s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes slideshow {
  0% {
    background-image: $bgImage1;
  }
  20% {
    background-image: $bgImage2;
  }
  40% {
    background-image: $bgImage3;
  }
  60% {
    background-image: $bgImage4;
  }
  80% {
    background-image: $bgImage5;
  }
  100% {
    background-image: $bgImage6;
  }
}
