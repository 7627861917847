.TableContainer {
  overflow-x: scroll;
  overflow-y: visible;
  height: auto;
}
.TableContainer::-webkit-scrollbar {
  display: none;
}

.TableContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
@media (width <= 1210px) {
  .TableContainer {
    .DataTable {
      width: max-content;
    }
  }
}
