.EmailForm {
  text-align: center;
  width: 50vw;
  height: 100vh;
  max-width: 960px;
  max-height: 1080px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 425px;
  }
}

.title {
  margin-top: 70px;
}

.description {
  margin-top: 50px;
}

.fields {
  width: 53%;
  height: 310px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formField {
  width: 100%;
  margin: 13px 0;
}

.link {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
}

@media screen and (max-width: 1024px) {
  .EmailForm {
    width: 100%;
    img {
      width: 328px;
    }
  }

  .title {
    h1 {
      font-size: 36px;
    }
  }

  .fields {
    width: 80%;
    height: 40vh;
    margin-top: 0px;
  }
}

@media screen and (max-height: 600px) {
  .EmailForm {
    height: auto;
    padding: 50px 0;
  }
}
