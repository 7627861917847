.fields {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  div {
    &:nth-child(1) {
      div {
        div {
          span {
            background-color: rgb(245, 245, 245);
          }
        }
      }
    }
  }
}

.btnsContainer {
  display: flex;
  gap: 20px;
  width: 50%;
  justify-content: center;
  margin: auto;
}

.hasDueDate {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  input {
    margin-right: 10px;
    border-radius: 5px;
  }
}
