.imgContainer {
  display: grid;
  place-items: center;
  border-radius: 50%;
  contain: strict;
  width: 57px;
  aspect-ratio: 1/1;
  margin-right: 5px;

  img{
    object-fit: cover;
  }
}

.nameContainer {
  cursor: pointer;
}

.nameContainer:hover {
  text-decoration: underline;
}
.Contact {
  height: 57px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
