.stats {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1285px) {
  .stats {
    justify-content: center;
    gap: 50px;
  }
}
