.dateTimePicker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .datePicker {
    width: 50%;
  }
  .timePicker {
    width: 30%;
    input {
      padding: 10px;
    }
  }
}

.btnsContainer {
  display: flex;
  gap: 20px;
  width: 50%;
  justify-content: center;
  margin: auto;
  margin-top: 30px;
}
