.historyContainer {
  overflow-x: auto;
  width: inherit;

  :nth-child(1) {
    width: fit-content;
  }
}

.btns {
  display: grid;

  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1.6fr 0.4fr;

  row-gap: 10px;
  column-gap: 5px;

  .docsBtns {
    width: 100%;
    justify-content: flex-end;
  }

  :nth-child(1) {
    grid-row-start: 1;
    grid-column-start: 1;

    grid-row-end: 2;
    grid-column-end: 3;
    justify-self: end;
  }

  :nth-child(2) {
    grid-row-start: 2;
    grid-column-start: 1;

    grid-row-end: 3;
    grid-column-end: 2;
    justify-self: start;
    align-self: center;
  }

  :nth-child(3) {
    grid-row-start: 2;
    grid-column-start: 2;

    grid-row-end: 3;
    grid-column-end: 3;
    justify-self: end;
  }
}

@media (width <= 550px) {
  .btns {
    .docsBtns {
      :nth-child(1) {
        width: 100%;
        svg {
          width: fit-content;
        }
      }

      :nth-child(2) {
        text-indent: -9999px;
        width: fit-content;
        gap: 0px !important;
      }
    }

    .btnDelete {
      text-indent: -9999px;
      width: fit-content;
      gap: 0px !important;
    }
  }
}

@media (width <= 450px) {
  .btns {
    button {
      width: 100px;
    }
  }
}
