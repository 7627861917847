.header {
  position: relative;
  width: 100%;
  background-color: white;
  padding: 35px 35px 20px 35px;
  display: flex;
  justify-content: space-between;
  justify-items: flex-start;
  box-shadow: 0px 2px 4px 0px rgba(39, 39, 39, 0.12);

  .page {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    svg {
      display: none;
    }

    h1 {
      display: block;
    }
  }
}

.message {
  font-size: 18px;
  color: var(--gray-soft);
  strong {
    color: var(--gray);
  }
}

@media (width <= 984px) {
  .header {
    .page {
      svg {
        display: block;
      }

      h1 {
        display: none;
      }
    }
  }
}

@media (width <= 500px) {
  .header {
    gap: 20px;

    span {
      font-size: 20px;
    }
  }
}
