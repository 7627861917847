.fields {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.btnsContainer {
  display: flex;
  gap: 20px;
  width: 50%;
  justify-content: center;
  margin: auto;
}

.hasDueDate {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  input {
    margin-right: 10px;
    border-radius: 5px;
  }
}
