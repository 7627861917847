.profileContainer {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

@media (width <= 685px) {
  .profileContainer {
    flex-direction: column;
  }
}
