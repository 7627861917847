.RecoveryForm {
  width: 80vw;
  height: 100vh;
  max-width: 960px;
  max-height: 1080px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 425px;
  }
}

.infoContainer {
  margin-top: 50px;
}

.subtitleContainer {
  margin-top: 50px;
}

.submitContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
  width: 50%;
}
