.LoginForm {
  width: 50vw;
  height: 100vh;
  max-width: 960px;
  max-height: 1080px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 425px;
  }
}

.title {
  margin-top: 60px;
}

.fields {
  width: 53%;
  height: 310px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formField {
  width: 100%;
}

.link {
  width: 100%;
  margin-bottom: 20px;
}

@media screen and (max-width: 1024px) {
  .LoginForm {
    width: 100%;
    img {
      width: 328px;
    }
  }

  .title {
    h1 {
      font-size: 36px;
    }
  }

  .fields {
    width: 80%;
  }
}

@media screen and (max-height: 600px) {
  .LoginForm {
    height: auto;
    padding: 50px 0;
  }
}
