.pqrsResponse {
  padding: 20px;
}

.form {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
