.RecoveryFieldContainer {
  display: grid;
  gap: 4px;
  margin: 10px 0;
  .RecoveryField {
    display: flex;
    gap: 5px;
  }

  .errorContainer {
    font-size: 10px;
  }
}
