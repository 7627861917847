.container {
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 3px 6px 0px rgba(153, 153, 153, 0.5);
  position: relative;
  overflow: hidden;
  border-radius: 20px;

  .imgContainer {
    margin-top: 15%;
    height: 165px;
    width: 165px;
    contain: content;
    display: grid;
    place-items: center;
    position: relative;

    .borderIcon {
      background-color: white;
      box-shadow: 0px 0px 5px 0px rgb(153, 153, 153);
      border: 4px solid white;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      position: absolute;
      display: grid;
      place-items: center;
      cursor: pointer;
      bottom: 3px;
      right: 3px;
      z-index: 1;

      div {
        position: relative;
        display: grid;
        place-items: center;
        background-color: var(--blue-deep);
        height: 95%;
        width: 95%;
        border-radius: 50%;

        input {
          position: absolute;
          width: 35px;
          opacity: 0;
        }
      }
    }

    .border {
      background-color: white;
      box-shadow: 0px 0px 5px 0px rgb(153, 153, 153);
      border: 3px solid white;
      border-radius: 50%;
      width: 160px;
      height: 160px;
      display: grid;
      place-items: center;
      object-fit: cover;
      contain: content;

      img {
        height: 150px;
        width: 150px;
        border-radius: 50%;
        object-fit: cover;
      }

      div {
        background-color: gray;
        height: 95%;
        width: 95%;
        border-radius: 50%;
      }
    }
  }

  .infoContainer {
    margin-top: 15px;
    display: flex;
    margin-left: 21px;
    margin-right: 21px;
    flex-direction: column;
    align-items: center;
    gap: 3px;
  }

  .iconContainer {
    width: 86px;
    height: 86px;
    display: grid;
    place-items: center;
    border: 2px solid rgba(128, 128, 128, 0.3);
    border-radius: 8px;
    margin-top: 17%;
    background-color: white;
    z-index: 1;
  }

  .infoDetailsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px 0;
    margin-left: 23px;
    margin-right: 23px;

    .textInfoContainer {
      display: flex;
      gap: 8px;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      justify-content: center;

      .Whatsapplink {
        text-decoration: underline;
        color: var(--green-bright);

        a {
          display: flex;
          gap: 8px;
        }
      }
    }
  }

  .background {
    position: absolute;
    z-index: 0;
    top: -20px;
  }

  .backgroundP1 {
    position: absolute;
    z-index: 0;

    :last-child {
      display: none;
    }
  }
}

@media (width <= 550px) {
  .infoDetailsContainer {
    :nth-child(2) {
      svg {
        display: none !important;
      }
    }
  }
}

@media (width <= 690px) {
  .container {
    width: 100% !important;

    .backgroundP1 {
      :first-child {
        display: none;
      }

      :last-child {
        display: block;
      }
    }
  }
}
@media (width <= 1165px) {
  .container {
    .infoDetailsContainer {
      .textInfoContainer {
        .email {
          white-space: normal;
          word-break: break-all;
        }
      }
    }
  }
}
