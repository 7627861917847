.layout {
  display: flex;
  height: 100vh;
  position: relative;
}

.pageContent {
  flex: 1;
  background-color: var(--white-soft);
  height: 100vh;
  overflow-y: scroll;
}

.componentContainer {
  padding: 25px 30px;
  width: 100%;
  height: fit-content;
}

@media (width<=550px) {
  .componentContainer{
    padding: 15px 20px;
  }
  
}
