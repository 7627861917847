.iconContainer {
  display: none;
  position: absolute;
  top: 50px;
  left: 43px;
}

.container {
  background-color: var(--blue-deep);
  padding: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .roleBox {
    cursor: pointer;
    svg {
      display: none;
    }
  }
}

@media (width <= 984px) {
  .iconContainer {
    display: block;
  }
  .container {
    .roleBox {
      svg {
        display: block;
      }
    }
  }
}
