.form {
  input {
    padding: 0.7em;
  }

  label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    input {
      margin-right: 10px;
      border-radius: 5px;
      padding: 8px;
    }
  }
}

.btnsContainer {
  display: flex;
  gap: 20px;
  width: 50%;
  justify-content: center;
  margin: auto;
}
