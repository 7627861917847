.formFieldInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;

  .inputFileContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    span {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .inputFile {
      background-color: #37374d;
      width: 165px;
      min-width: 165px;
      height: 40px;
      display: inline-block;
      border-radius: 0 0.5rem 0.5rem 0;

      input {
        width: inherit;
      }
    }
  }
}

@media (width <= 400px) {
  .formFieldInput {
    .inputFileContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .inputFile {
        position: relative !important;
        border-radius: 0.5rem;
      }
    }
  }
}
