.select {
  color: var(--white-full);
  padding: 1px 10px;
  border-radius: 6px;
}

.statusBox {
  color: var(--white-full);
  padding: 1px 5px;
  border-radius: 6px;
  text-align: center;
  text-transform: capitalize;
}

.select option {
  background-color: #fff;
  color: #000;
}

.select:not([size]) {
  background-image: url("../../../../assets/Icons/Arrow.svg");
}
