.InputRecoveryContainer {
  display: flex;
  .InputRecovery {
    appearance: none;
    max-width: 40px;
    padding: 5px;
    height: 40px;
    text-align: center;
  }
}

.InputError {
  border: 2px solid red;
}
