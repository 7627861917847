.inputPhoneContainer {
  position: relative;
  width: 100%;

  .inputsContainer {
    display: flex;
    gap: 20px;
    flex-direction: row;

    .inputPhoneContainer {
      display: flex;
      gap: 10px;
      flex-direction: row;
    }

    .prefixContainer {
      display: flex;
      width: auto;
      height: 100%;
      justify-content: center;
      align-items: center;
      position: relative;

      .dropdown_header {
        display: flex;
        width: 70%;
        height: 100%;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }

      .prefix_header {
        border-left: 1px solid #d1d5db;
        display: grid;
        place-items: center;
        height: 100%;
        width: 30%;
      }
    }

    .dropdown {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 50px;
      border: 1px solid;
      border-color: #d1d5db;
      border-radius: 0.5rem;
      background-color: rgb(249 250 251 / var(--tw-bg-opacity));
      transition: 0.7s;

      .dropdown_content {
        position: absolute;
        background-color: rgb(249 250 251 / var(--tw-bg-opacity));
        width: 70.3%;
        margin: 0.5px;
        padding: 0 10px;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        z-index: 999;
        left: -1px;
        height: 182px;
        overflow-y: scroll;

        transition: 0.3s;

        li {
          display: flex;
          flex-direction: row;
          gap: 10px;
          padding: 10px 0;
          cursor: pointer;
          border-bottom: 1px solid;
          border-color: #d1d5db;

          :last-child {
            border-color: none;
          }
        }
      }

      .dropdown_content::-webkit-scrollbar {
        border-radius: 10px;
      }

      .dropdown_content::-webkit-scrollbar:vertical {
        width: 6px;
      }

      .dropdown_content::-webkit-scrollbar-thumb {
        background-color: #d1d5db;
        border-radius: 10px;
      }
    }
  }
}

@media (350px < width <= 500px) {
  .inputPhoneContainer {
    .inputsContainer {
      display: flex;
      flex-direction: column;
      gap: 15px;
      input {
        width: 100% !important;
      }
    }
  }
}

@media (width <= 350px) {
  .container {
    .inputsContainer {
      display: flex;
      flex-direction: column;
      gap: 15px;
      input {
        width: 100% !important;
      }
    }

    .dropdown {
      .dropdown_header {
        width: 75% !important;
      }
      .prefix_header {
        width: 25% !important;
      }
    }
  }
}
