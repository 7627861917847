.container {
  width: inherit;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .accountantCards {
    display: flex;
    justify-content: center;
    gap: 20px 20px;
    height: auto;
    flex-wrap: wrap;
    padding: 20px 0;

    .accountantInfo {
      width: fit-content;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }
}

@media (width <= 720px) {
  .container {
    .searchBarContainer {
      flex-direction: column;
      gap: 10px;

      .searchInputContainer {
        width: 100%;
      }
    }
  }
}

@media (width <= 610px) {
  .accountantCards {
    padding: 20px 15px !important;
  }
}

// Remove "x" in input search
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
