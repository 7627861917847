:root {
  --blue-deep: #25253f;
  --blue-soft: #2f2f45;
  --blue-clear: #37374D;
  --cyan: #27d7f9;
  --black-full: #000;
  --gray-soft: #282828;
  --gray-clear: #6b7280;
  --white-full: #ffffff;
  --white-soft: #f3f4f6;
  --green-bright: #20BC3D;
}
