.pqrsTable {
  width: 100%;
  border-radius: 8px;
  table {
    background-color: var(--white-full);
  }
}

.header {
  display: flex;
  padding: 15px 30px;
  gap: 20px;
  border-radius: 8px 8px 0 0;
  background-color: var(--white-full);
}

.topBar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  button {
    width: calc(99.8% / 4);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--blue-clear);
    color: var(--white-full);
    font-weight: normal;
    padding: 5px 10px;
  }
}

@media screen and (max-width: 600px){
  .topBar{
    button{
      font-size: 2.7vw;
      height: 35px;
    }
  }
}
