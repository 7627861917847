@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sideMenu {
  flex: 0 0 20%;
  max-width: 384px;
  min-width:296px;
  height: 100vh;
  background-color: var(--blue-soft);
}

/* Sidemenu funcionality */

.sideInputController {
  display: none;
  visibility: hidden;
}

@media (width <= 984px) {
  .sideInputController {
    visibility: inherit;
    cursor: pointer;
    display: block;
    position: absolute;
    z-index: 100;
    width: 35px;
    height: 35px;
    top: 31px;
    left: 30px;
    opacity: 0;
    transition: 1s;
  }

  .sideInputController:checked {
    top: 44px;
    left: 37px;
  }

  .sideInputController:checked ~ .sideMenu {
    left: 0px;
  }

  .sideMenu {
    position: absolute;
    transition: 0.5s left;
    left: -1000px;
    z-index: 5;
    width: 100vw;
    max-width: 100vw;
  }
}
