.Recovery {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100vw;
}

@media screen and (max-width: 1024px) {
  .imageContainer {
    display: none;
  }
}
