.title {
  padding: 10px 30px;
  padding-left: 38px;
  text-align: left;
}

.menus {
  height: calc(100vh - 122px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.secondMenu {
  margin-bottom: 50px;
}
