.fc-toolbar-chunk:nth-child(2) {
  display: flex;
  align-items: center;

  h2::first-letter {
    text-transform: capitalize;
  }

  button {
    color: var(--blue-soft);
    background-color: transparent;
    border-color: transparent;
  }
}

.fc-toolbar-chunk:nth-child(3) {
  button {
    background-color: transparent;
    color: var(--blue-soft);
    font-weight: 400;
    border-color: #dce0e5;
  }

  .fc .fc-button-primary:hover {
    background-color: "#76818D" !important;
  }

  .button-active {
    background-color: var(--blue-soft);
  }
}

// Capitalize month header in year view
.fc-multimonth-title::first-letter {
  text-transform: capitalize;
}

// Decoration for header week view
.fc-list-day-cushion.fc-cell-shaded {
  background-color: #1a252f;
  color: white;

  :nth-child(1) {
    text-transform: capitalize;
  }
}

// Month view
.fc-dayGridMonth-view.fc-view.fc-daygrid {
  th {
    text-transform: capitalize;
  }
}
.fc-daygrid-body.fc-daygrid-body-unbalanced {
  td {
    height: 110px;

    .fc-daygrid-day-frame {
      max-height: 120px;
    }
  }
}

// Year view
.fc-multiMonthYear-view {
  th {
    text-transform: capitalize;
  }

  .fc-daygrid-day-events {
    display: flex;
    justify-content: flex-end;
    height: -webkit-fill-available;
    padding: 2px;
    justify-content: flex-end;
    flex-wrap: wrap;

    .fc-daygrid-event-harness {
      width: fit-content;

      a {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: red;

        div {
          display: none;
        }
      }
    }
  }
}

// Popover event view
.fc-popover.fc-more-popover {
  z-index: 10;

  .fc-popover-body {
    .fc-daygrid-event-harness {
      .event-container {
        width: 100%;
        background-color: white !important;
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: flex-start;

        div {
          display: block !important;
        }

        span {
          color: black !important;
          display: block !important;
        }
      }
    }
  }
}

// List view
.fc-list-table {
  .fc-list-event-graphic {
    display: none;
  }

  td {
    vertical-align: middle;
  }
}

@media (width < 950px) {
  .fc-view-harness.fc-view-harness-active {
    height: 600px !important;
  }
}

@media (width < 800px) {
  .fc-view-harness.fc-view-harness-active {
    height: 690px !important;
  }
}

@media (width < 700px) {
  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    display: flex !important;

    .fc-toolbar-title {
      font-size: 22px !important;
    }
  }
}

@media (width < 600px) {
  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    .fc-toolbar-title {
      font-size: 22px !important;
    }

    .fc-toolbar-chunk:nth-child(1) {
      order: 1;
    }

    .fc-toolbar-chunk:nth-child(2) {
      order: 0;
      justify-content: center;
      width: 100%;
    }

    .fc-toolbar-chunk:nth-child(3) {
      order: 1;
    }
  }

  .fc-view-harness.fc-view-harness-active {
    height: 500px !important;
  }
}
