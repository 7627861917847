.RecoveryPassword {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
  justify-content: center;
  align-items: center;

  p {
    margin-top: 60px;
  }

  .passwordFieldContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-top: 30px;
  }

  .passwordValue {
    width: 90%;
    height: 40px;
    border: 3px solid #d4c6c6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    position: relative;

    .passwordBlur {
      width: 100%;
      filter: blur(8px);
      position: relative;
      z-index: 0;
    }

    .passwordBlur:hover {
      filter: blur(0px);
    }

    .copyContainer {
      position: absolute;
      right: 5px;
      cursor: pointer;
      z-index: 1;
      aspect-ratio: 1/1;
      width: 30px;
      display: grid;
      place-items: center;
    }

    .copyContainer:hover ~ .passwordBlur {
      filter: blur(0px) !important;
    }
  }

  .buttonContainer {
    margin-top: 40px;
    margin-top: 40px;
    width: 90%;
  }
}
