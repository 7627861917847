.EmailRecovery {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .imageContainer {
    display: none;
  }
}
