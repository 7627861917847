.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 20px 20px;
}

.pqrsType {
  width: 170px;
  height: 40px;
  color: var(--white-full);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.description {
  padding: 20px;
}
