.container {
  width: 100%;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.1);

  .headerContainer {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 24px;
    display: flex;
    border-bottom: 1px solid #d1d5db;
    align-items: center;
    justify-content: space-between;

    div {
      align-items: center;
      gap: 10px;
      display: flex;
    }
  }
}
