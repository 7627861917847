.floatMenu {
  position: absolute;
  width: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 5;
  transition: 0.4s;
  overflow: hidden;
  top: 70%;
  right: -10px;

  button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
}

.modalOpen {
  opacity: 1;
  visibility: initial;
}

.modalClosed {
  opacity: 0;
  visibility: hidden;
}
