.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 60px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.body {
  overflow: initial !important;
}
