.RecoveryPassword {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;

  img {
    width: 425px;
  }
}
