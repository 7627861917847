.header {
  height: 20%;
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  justify-content: center;

  span {
    margin-top: 23px;
  }
}

.infoContainer {
  height: 80%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 30px 0;

  .textInfoContainer {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;

    .Whatsapplink {
      text-decoration: underline;
      color: var(--green-bright);

      a {
        display: flex;
        gap: 8px;
      }
    }
  }
}

@media (width <= 550px) {
  .infoContainer {
    padding: 30px 15px;
    :nth-child(4) {
      svg {
        display: none !important;
      }
    }
  }
}

@media (width <= 690px) {
  .header {
    span {
      margin-bottom: 20px;
    }
  }
}
